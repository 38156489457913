.about_container{
display: grid;
grid-template-columns: 35% 50%;
gap: 15%;
align-items: center;
}

.about_me{
width: 100%;
aspect-ratio: 1/1;
border-radius: 2rem;
background: linear-gradient(45deg, transparent, var(--color-primary),transparent);
display: grid;
place-items: center;
}

.about_me-image{
height: 100%;
border-radius: 2rem;
overflow: hidden;
transform: rotate(10deg);
transition: var(--transition);
}

.about_me-image:hover{
transform: rotate(0);
}

.about_cards{
    display: grid;
    /* justify-content: center; */
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.about_card{
    /* display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; */
    background: var(--color-bg-variant);
    border: 1px solid transparent;
    border-radius: 1rem;
    padding: 2rem;
    text-align: center;
    transition: var(--transition);
}

.about_card:hover{
    background: transparent;
    border-color: var(--color-primary-variant);
    cursor: default;
}

.about_icon{
color: var(--color-primary);
font-size: 1.4rem;
margin-bottom: 1rem;
}

.about_card h5{
font-size: 0.95rem;
}

.about_card small{
font-size: 0.7rem;
color: var(--color-light);
}

.about_content p{
word-wrap: break-word;
margin: 2rem 0 2.6rem;
color: var(--color-light);
}

.about_card small li{
    list-style: disc ;
}

/**----------Medium Screens/Tablets---------**/

@media screen and (max-width:1024px) {
  
    .about_container{
        /* grid-template-columns: 1fr;
        gap: 0; */
        display: block;
      }
    
        .about_me{
        width: 50%;
        margin: 2rem auto 4rem;
        }
    
        .about_content p{
        margin: 1rem 0 1.5rem;
        }
  
  }

  /*--Phone/Small Screens--*/
  
  @media screen and (max-width: 600px) {
    
        .about_me{
        width: 65%;
        margin: 0 auto 3rem;
        }

        .about_cards{
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        }
    
        .about_content {
        text-align: center;
        }

        .about_content p{
        margin: 1.5rem 0;
        } 
  
  }
