.portfolio_container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio_item{
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    
}

.portfolio_item:hover{
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio_item-image{
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio_item h3{
    margin: 1.2rem 0 2rem;
}

.portfolio_item-cta{
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
}



/**----------Medium Screens/Tablets---------**/

@media screen and (max-width:1024px) {
  
    .portfolio_container{
     grid-template-columns: 1fr 1fr;
     gap: 1.2rem;
        /* display: block; */
      }   
  
  }

  /*--Phone/Small Screens--*/
  
  @media screen and (max-width: 600px) {
    
    .portfolio_container{
        grid-template-columns: 1fr;
        gap: 1rem;
           /* display: block; */
         }
  
  }